import React from 'react';

import useContentParser from '../../../hooks/useContentParser';

const ContentBlock = ({ content }) => {
    const { parsedContent } = useContentParser(content);
    return (
        <div className="contentBlock">
            {parsedContent}
        </div>
    );
}

export default ContentBlock;