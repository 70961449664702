import React, { useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'



const Navigation = (props) => {

    return (
        <nav>
            <div className='nav'>
                <NavLink to="/"><h1>HOME</h1></NavLink>
                <NavLink to="/playground"><h1>PLAYGROUND</h1></NavLink>
                <NavLink to="/contact"><h1>CONTACT US</h1></NavLink>
            </div>
            <Outlet />
        </nav>
    )
}

export default Navigation