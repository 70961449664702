import React from 'react'

import logoMobile from '../../assets/images/logo-mobile.png'
import logoDesktop from '../../assets/images/logo-desktop.png'

import useWindowSize from '../../hooks/useWindowSize'

const Logo = () => {
    const { width } = useWindowSize()
    return (
        <div className="logo">
            <img src={width > 768 ? logoDesktop : logoMobile} alt="Logo" />
        </div>
    )
}

export default Logo