import React from 'react'

const ProjectCardTitlePanel = ({ title, subtitle }) => {
    return (
        <div className="projectCardTitlePanel">
            <h2 className="projectCardTitle">{title}</h2>
            <p className="projectCardSubtitle">{subtitle}</p>
        </div>
    );
}

export default ProjectCardTitlePanel;