import React from 'react';

import ContentBlock from '../ContentBlock/ContentBlock';

const Modal = ({ show, content, closeModal }) => {
    return (
        <div className={show ? "modal active" : "modal"} onClick={closeModal}>
            <div className="modalContent" onClick={e => e.stopPropagation()}>
                <ContentBlock content={content} />
            </div>
        </div>
    );
}

export default Modal;