import React from 'react'

//import images
import playgroundButton from '../../assets/images/playground-button.png'
import test1 from '../../assets/images/projects/test/1.png'
import test2 from '../../assets/images/projects/test/2.png'
import test3 from '../../assets/images/projects/test/3.png'
import test4 from '../../assets/images/projects/test/4.png'
import curecup1 from '../../assets/images/projects/cure-cup/1.png'
import curecup2 from '../../assets/images/projects/cure-cup/2.png'
import curecup3 from '../../assets/images/projects/cure-cup/3.png'
import curecup4 from '../../assets/images/projects/cure-cup/4.png'
import hopefall1 from '../../assets/images/projects/hopefall/1.png'
import hopefall2 from '../../assets/images/projects/hopefall/2.png'
import hopefall3 from '../../assets/images/projects/hopefall/3.png'
import hopefall4 from '../../assets/images/projects/hopefall/4.png'
import vtamapsnap1 from '../../assets/images/projects/vtama-psnap/1.png'
import vtamapsnap2 from '../../assets/images/projects/vtama-psnap/2.png'
import vtamapsnap3 from '../../assets/images/projects/vtama-psnap/3.png'
import vtamapsnap4 from '../../assets/images/projects/vtama-psnap/4.png'
import alyeska1 from '../../assets/images/projects/alyeska/alyeska1.png'
import alyeska2 from '../../assets/images/projects/alyeska/alyeska2.gif'
import alyeska3 from '../../assets/images/projects/alyeska/alyeska3.gif'
import alyeska4 from '../../assets/images/projects/alyeska/alyeska4.jpg'
import alyeska5 from '../../assets/images/projects/alyeska/alyeska5.png'
import wrangell1 from '../../assets/images/projects/wrangell/wrangell1.png'
import wrangell2 from '../../assets/images/projects/wrangell/wrangell2.gif'
import wrangell3 from '../../assets/images/projects/wrangell/wrangell3.gif'
import alyeska6 from '../../assets/images/projects/alyeska/alyeska6.jpeg'
import kittywop1 from '../../assets/images/projects/kittywop/kittywop1.png'
import kittywop2 from '../../assets/images/projects/kittywop/kittywop2.png'
import kittywop3 from '../../assets/images/projects/kittywop/kittywop3.gif'
import kittywop4 from '../../assets/images/projects/kittywop/kittywop4.gif'
import kittywop5 from '../../assets/images/projects/kittywop/kittywop5.gif'
import kittywop6 from '../../assets/images/projects/kittywop/kittywop6.gif'
import kittywop7 from '../../assets/images/projects/kittywop/kittywop7.gif'
import kittywop8 from '../../assets/images/projects/kittywop/kittywop8.gif'
import kittywop9 from '../../assets/images/projects/kittywop/kittywop9.gif'
import kittywop10 from '../../assets/images/projects/kittywop/kittywop10.gif'
import kittywop11 from '../../assets/images/projects/kittywop/kittywop11.gif'
import kittywop12 from '../../assets/images/projects/kittywop/kittywop12.gif'
import kittywop13 from '../../assets/images/projects/kittywop/kittywop13.gif'
import kittywop14 from '../../assets/images/projects/kittywop/kittywop14.gif'
import kittywop15 from '../../assets/images/projects/kittywop/kittywop15.gif'
import wigglewop from '../../assets/images/projects/kittywop/wigglewop.mp4'

import tannen2 from '../../assets/images/projects/tannen2.jpeg'
import tbapi from '../../assets/images/projects/tbapi.jpeg'
import tbtiktok1 from '../../assets/images/projects/tbtiktok1.jpeg'
import yacht1 from '../../assets/images/projects/yacht1.png'

import gamebox from '../../assets/images/projects/gamebox.jpg'
import franks from '../../assets/images/projects/franks.png'
import remix from '../../assets/images/projects/remix/remix.png'
import remix4 from '../../assets/images/projects/remix/remix4.mp4'

import vtamaVid from '../../assets/images/projects/vtama-psnap/VTAMA.mp4'


const homeContent = {
    archive: [
        {
            id: 1,
            title: 'MOONS THE CURE CUP',
            subtitle: 'Something Something',
            tags: ['Product Development', '3D Modeling', '3D Printing', 'Marketing', 'Creative'],
            image: [curecup4, curecup3, curecup2, curecup1],
            content: [
                {
                    type: 'h1',
                    content: 'Heading 1'

                },
                {
                    type: 'p',
                    content: 'Paragraph 1'
                },
                {
                    type: 'image',
                    content: playgroundButton,
                    alt: 'Placeholder Image'
                }
            ]
        },
        {
            id: 16,
            title: 'Evrysdi',
            subtitle: 'Conference Booth',
            tags: ['Javascript', 'Web Design', 'Marketing', 'Dev', 'Node', 'React'],
            image: [test4, test3, test2, test1],
            content: [
                {
                    "type": "h1",
                    "content": "Evrysdi - Conference Booth Experience"
                },
                {
                    "type": "p",
                    "content": "For Evrysdi, we developed a unique conference booth experience that engaged visitors by capturing their image, guiding them through a quiz, and generating personalized 4K videos featuring their face. The experience was designed to be fast and interactive, allowing patients to receive their custom video almost instantly after completing the quiz."
                },
                {
                    "type": "p",
                    "content": "The challenge was to ensure quick rendering times for the videos, and we succeeded in getting the process down to under 10 seconds on a Mac laptop. This required building a custom Node.js server that utilized FFmpeg for video encoding, ensuring high-quality 4K renders while maintaining speed."
                },
                {
                    "type": "p",
                    "content": "We developed the entire experience, starting with the iPad interface for capturing images and guiding users through the quiz. The Node.js server handled the heavy lifting for video rendering, while the FFmpeg encoding took care of processing the videos. Additionally, we implemented email automation to send users their finished videos and created an admin interface for employees to manage the experience, track visitor data, and monitor performance."
                }
            ]

        },
        {
            id: 2,
            title: 'ARC HOPEFALL',
            subtitle: 'Something Something',
            tags: ['3D Model Generation', 'Marketing', 'Creative'],
            image: [hopefall4, hopefall3, hopefall2, hopefall1],
            content: [
                {
                    type: 'h1',
                    content: 'Heading 1'

                },
                {
                    type: 'p',
                    content: 'Paragraph 1'
                },
                {
                    type: 'image',
                    content: playgroundButton,
                    alt: 'Placeholder Image'
                }
            ]
        },
        {
            id: 4,
            title: 'ALPD',
            subtitle: 'Subtitle 5',
            image: [test4, test3, test2, test1],
            tags: ['Marketing', 'Interactive Video', 'Creative'],
            content: [
                {
                    type: 'h1',
                    content: 'https://hppdetective.com/enter-site/'

                },
                {
                    type: 'p',
                    content: 'Paragraph 1'
                },
                {
                    type: 'image',
                    content: playgroundButton,
                    alt: 'Placeholder Image'
                }
            ]
        },
        {
            id: 12,
            title: 'MMs Gamebox',
            image: [gamebox],
            tags: ['Product Development', 'Marketing', 'Creative'],
            content: [
                {
                    type: 'h1',
                    content: 'Heading 1'

                },
                {
                    type: 'p',
                    content: 'Paragraph 1'
                },
                {
                    type: 'p',
                    content: 'https://player.vimeo.com/video/251439466'
                },
                {
                    type: 'image',
                    content: playgroundButton,
                    alt: 'Placeholder Image'
                }
            ]
        },
        {
            id: 14,
            title: 'Lowes In-a-snap',
            subtitle: 'Subtitle 5',
            image: [test4, test3, test2, test1],
            tags: ['Social Media', 'Marketing', 'Creative'],
            content: [
                {
                    type: 'h1',
                    content: 'Heading 1'

                },
                {
                    type: 'p',
                    content: 'Paragraph 1'
                },
                {
                    type: 'image',
                    content: playgroundButton,
                    alt: 'Placeholder Image'
                }
            ]
        },
        {
            id: 10,
            title: 'ATT Sales Portal (data visualization)',
            tags: ['Data Visualization', 'Web Development', 'Web Design', 'Javascript', 'Dev', 'API Integration'],
            image: [test4, test3, test2, test1],
            content: [
                {
                    "type": "h1",
                    "content": "AT&T Data Visualization Tools"
                },
                {
                    "type": "p",
                    "content": "I developed a series of data visualization tools for AT&T's sales teams across the country. These tools were designed to help the teams analyze and interpret complex sales data more easily, ultimately improving their decision-making and workflow."
                },
                {
                    "type": "p",
                    "content": "The project involved integrating a web of APIs with various AT&T internal systems, pulling in data from multiple sources and providing real-time insights in an intuitive interface. The goal was to create a seamless experience for the users, making it easy for them to visualize key performance metrics and trends."
                },
                {
                    "type": "p",
                    "content": "I focused on building a clean, user-friendly UI that allowed users to interact with the data in meaningful ways—whether that was through interactive charts, graphs, or filtering options. The result was a set of tools that streamlined workflows and helped sales teams make data-driven decisions more efficiently."
                }
            ]

        }
    ],
    projects: [
        {
            id: 17,
            title: 'Alyeska Pipeline Website',
            subtitle: 'A wordpress site',
            image: [alyeska6],
            tags: ['Wordpress', 'Javascript', 'Web Design', 'Marketing', 'Dev'],
            content: [
                {
                    type: 'h1',
                    content: 'Alyeska Pipeline Website'

                },
                {
                    type: 'p',
                    content: "In this project, I had the privilege of designing, developing, and maintaining Alyeska-Pipe.com, the official website of Alyeska Pipeline Service Company, the operator of the Trans Alaska Pipeline System (TAPS). The objective was to create a modern, user-friendly platform that effectively communicates Alyeska's commitment to safely and reliably operating TAPS, while providing stakeholders and the public with comprehensive information about the pipeline's history, operations, and environmental initiatives."
                },
                {
                    type: 'image',
                    content: alyeska2
                },
                {
                    type: 'h3',
                    content: 'Key Features & Technologies:'
                },
                {
                    type: 'ul',
                    content: [
                        {
                            type: 'li',
                            content: 'WordPress CMS: The Alyeska team had been using Wordpress for some time and were worried about having to learn a new CMS. So I stuck with Wordpress but added a custom theme and plugins to make it easier to update. To manage the new theme, I made a series of training videos to help the team and future developers understand how to use the new site.'
                        },
                        {
                            type: 'li',
                            content: 'Custom Theme Development: I developed a custom WordPress theme with component blocks all designed around Alyesla\'s branding.'
                        },
                        {
                            type: 'li',
                            content: 'Responsive Design: The website was designed with a mobile-first approach, ensuring optimal viewing experiences across a variety of devices, from desktops to smartphones. This design strategy improved accessibility and user engagement.'
                        },
                        {
                            type: 'li',
                            content: 'SEO Optimization: I implemented SEO best practices, including optimized meta tags, clean URL structures, and schema markup, to enhance the site\'s visibility on search engines and attract a broader audience.'
                        },
                        {
                            type: 'li',
                            content: 'Performance Optimization: To ensure fast load times and a smooth user experience, I optimized images, leveraged browser caching, implimented lazy loading and minimized code. These enhancements reduced bounce rates and improved overall site performance.'
                        },
                        {
                            type: 'li',
                            content: 'Compliance with Industry Standards: The website was developed in adherence to industry standards and regulations, ensuring that all content and functionalities comply with relevant guidelines. This included accessibility features and data protection measures.'
                        }
                    ]
                },
                {
                    type: 'image',
                    content: alyeska3
                },
                {
                    type: 'button',
                    content: 'Visit Site',
                    link: 'https://alyeska-pipe.com/'
                }
            ]
        },
        {
            id: 19,
            title: 'Wrangell Alaska Tourism Website',
            image: [wrangell1],
            tags: ['React', 'Javascript', 'Contentful', 'Web Design', 'Marketing', 'Dev'],
            content: [
                {
                    "type": "h1",
                    "content": "Wrangell Alaska Tourism Website"
                },
                {
                    "type": "p",
                    "content": "For this project, I had the privilege of designing, building, and managing TravelWrangell.com, the official tourism website for Wrangell, Alaska. The goal was to create a modern, user-friendly platform that showcases Wrangell's unique blend of natural beauty, rich history, and vibrant community events, encouraging visitors to explore beyond the typical tourist paths."
                },
                {
                    "type": "image",
                    "content": wrangell3
                },
                {
                    "type": "h3",
                    "content": "Key Features & Technologies:"
                },
                {
                    "type": "ul",
                    "content": [
                        {
                            "type": "li",
                            "content": "React.js: The client didn't have a preference of platform so I used React js, as it's what I have the most experience in. This allowed me to work faster and build a site that is easy to update and maintain."
                        },
                        {
                            "type": "li",
                            "content": "Contentful CMS: The client wasn't fond of wordpress, so I integrated Contentful as the headless CMS, allowing the team to manage and update content without technical expertise."
                        },
                        {
                            "type": "li",
                            "content": "Custom Design: The website features a custom design that highlights Wrangell's natural landscapes, cultural sites, and community events, aligning with the brand's identity and mission."
                        },
                        {
                            "type": "li",
                            "content": "Event Promotion: The site includes a dedicated section for festivals and events, providing up-to-date information on local happenings and encouraging community engagement."
                        },
                        {
                            "type": "li",
                            "content": "Social Media Integration: I integrated social media feeds and sharing options, allowing visitors to connect with Wrangell's vibrant online community and stay informed about the latest news and events."
                        },
                        {
                            "type": "li",
                            "content": "SEO and Analytics: I optimized the website for search engines and integrated analytics tools to track user engagement, enabling data-driven decisions for continuous improvement."
                        }
                    ]
                },
                {
                    "type": "image",
                    "content": wrangell2
                },
                {
                    "type": "button",
                    "content": "Visit Site",
                    "link": "https://www.travelwrangell.com"
                }
            ]
        },
        {
            id: 20,
            title: 'Tannens Magic Shop',
            image: [tannen2],
            tags: ['Web Development', 'Web Design', 'Shopify', 'Dev'],
            content: [
                {
                    type: 'h1',
                    content: 'Tannens Magic Shop'
                },
                {
                    type: 'p',
                    content: "For this project, I had the opportunity to design, build, and manage TannensMagic.com, the online store for Tannen's Magic, one of the oldest magic shops in America. Tannen's has been serving magicians since 1925, and my goal was to create a modern, user-friendly online experience while honoring its rich history."
                },
                {
                    type: 'h3',
                    content: 'Key Contributions:'
                },
                {
                    type: 'ul',
                    content: [
                        {
                            type: 'li',
                            content: 'Shopify Development: I built the site using Shopify, ensuring a seamless e-commerce experience with a clean, easy-to-navigate layout.'
                        },
                        {
                            type: 'li',
                            content: 'Inventory Management: I was responsible for organizing and managing the store\'s inventory, transforming a chaotic set of spreadsheets filled with old and duplicated data to an inventory that is accurate and up-to-date for customers.'
                        },
                        {
                            type: 'li',
                            content: "Information Architecture: I refined the site's information architecture to improve navigation, making it easy for customers to find everything from magic tricks to books and playing cards."
                        },
                        {
                            type: 'li',
                            content: 'Site Management: I handled ongoing updates to the site, managing product launches, promotions, and customer inquiries to ensure smooth daily operations.'
                        }
                    ]
                },
                {
                    type: 'button',
                    content: 'Visit Site',
                    link: 'https://tannensmagic.com/'
                }
            ]
        },
        {
            id: 3,
            title: 'VTAMA PSNAP',
            subtitle: 'Bringing the First Snap AR Lens in Healthcare to Life',
            tags: ['AR', 'Snapchat', 'Social Media', 'Gaming', 'Marketing', 'Creative'],
            image: [vtamapsnap4, vtamapsnap3, vtamapsnap2, vtamapsnap1],
            content: [
                {
                    type: 'h1',
                    content: 'Objectives'

                },
                {
                    type: 'p',
                    content: "VTAMA cream is a topical treatment for plaque psoriasis. To celebrate World Psoriasis Day on October 29th, 2023, VTAMA cream campaign launched the first AR Lens in Healthcare, to create awareness and engage our target audience. Patients with plaque psoriasis are 5x more likely to be gamers than the general population, and they over index as mobile gamers. Furthermore, 89% of gamers say gaming provides them with stress relief and stress is one of the common triggers of plaque psoriasis flare ups."
                },
                {
                    type: 'h1',
                    content: 'Strategy and Execution'

                },
                {
                    type: 'p',
                    content: "VTAMA cream's campaign AR Lens highlights the innovation that is possible within a heavily-regulated space like pharmaceutical marketing. In the first branded pharmaceutical AR lens to launch on Snapchat, VTAMA cream marketing team brought their Topical Uprising TV spot to life. The Snapchat audience loves interactive filters, especially gaming ones. The AR Lens was designed to be a fun, interactive experience that would resonate with the target audience and encourage them to share it with their friends."
                },
                {
                    type: 'p',
                    content: 'Users would "itch" the screen to simulate “scratching” to jump; as itch is a common symptom of plaque psoriasis. If users were hit by too many obstacles, the game would end. Users were encouraged to share their high score with friends.'
                },
                {
                    type: 'h1',
                    content: 'Results'
                },
                {
                    type: 'p',
                    content: 'In 24-hours on World Psoriasis Day, the First AR Lens reached over 6M users, garnered 8.2M impressions, 16K clicks and over 41,000 shares of the game.'
                },
                {
                    type: 'p',
                    content: 'The AR Lens Snapchat campaign contributed to a record-breaking day of website users resulting in a 215% increase over the daily average, and on-site user engagement that significantly outperformed Snapchat and Dermavant expectations showcasing the AR lens not only drove mass awareness but reached users who were interested in learning more about VTAMA cream.'
                },
                {
                    type: 'p',
                    content: 'The game also performed above the benchmark set by other (non-healthcare) AR lens for average play time, share rate, and click rate. And it was picked up by AdWeek as an example of innovation in healthcare advertising.'
                },
                {
                    type: 'video',
                    content: vtamaVid,
                }
            ]
        },


        {
            id: 7,
            title: 'Taco Bell API',
            subtitle: 'An API for IRL Ordering',
            tags: ['Marketing', 'Dev', 'API Development', 'API Integration'],
            image: [tbapi],
            content: [
                {
                    "type": "h1",
                    "content": "Taco Bell API - IRL Ordering Integration"
                },
                {
                    "type": "p",
                    "content": "I developed the Taco Bell API, a powerful integration that allows developers to enable real-world ordering through their own creations. The API provides a seamless way for third-party applications and platforms to incorporate Taco Bell's menu and ordering functionality, creating new opportunities for user engagement and interaction."
                },
                {
                    "type": "p",
                    "content": "As part of the launch, we hosted a unique event in Decentraland, where users could interact with a virtual Taco Bell and experience the ordering process in a fully immersive environment. Additionally, we created a fully functional Taco Bell experience within Roblox, allowing players to virtually order Taco Bell and have it delivered to their real-world location."
                },
                {
                    "type": "p",
                    "content": "The development of the Taco Bell API and its integration into these virtual environments was a groundbreaking way to blend the physical and digital worlds. This project not only provided a new way for users to engage with the brand but also opened up new possibilities for developers looking to bring real-world functionality into their virtual worlds."
                }
            ]

        },
        {
            id: 8,
            title: 'Yacht - Album Launch Campaign',
            subtitle: 'Uber API Integration',
            tags: ['Web Development', 'Web Design', 'Marketing', 'Dev', 'Creative', 'API Integration'],
            image: [yacht1],
            content: [
                {
                    "type": "h1",
                    "content": "Yacht - Album Launch Campaign"
                },
                {
                    "type": "p",
                    "content": "While at BBDO, my team and I worked on the launch campaign for Yacht's album release, a unique integration between the electronic pop group YACHT and the Uber API. The campaign creatively tied Uber's surge pricing model to the release of YACHT's single *L.A. Plays Itself*, offering users an interactive way to experience music based on real-time data."
                },
                {
                    "type": "p",
                    "content": "As Uber's surge pricing fluctuated in Los Angeles, users could unlock exclusive remixes of the song. When surge pricing hit a 1.1x multiplier, the original track was released, and at a 2x surge, a special remix dropped. This concept tied directly into the themes of YACHT's album *I Thought The Future Would Be Cooler*."
                },
                {
                    "type": "p",
                    "content": "I was responsible for the creative concept, technical execution, and integration of the Uber API, ensuring the seamless connection between pricing data and the release of the music. The campaign provided an immersive and interactive experience, combining music and real-time data in a way that was both fun and engaging for users."
                },
                {
                    "type": "youtube",
                    "content": "hA_QyjzqMAE"
                }
            ]

        },
        {
            id: 9,
            title: "Remix with M - M&M's",
            image: [remix],
            tags: ['Web Development', 'AR', 'Web Design', 'Marketing', 'Dev', 'Creative'],
            content: [
                {
                    "type": "h1",
                    "content": "Remix with M - M&M's"
                },
                {
                    "type": "p",
                    "content": "For the Remix with M project for M&M's, we were responsible for the creative concepting, prototyping, and development of the M&Ms 75th anniversary campaign. Part of the campaign had Aloe Blacc and Zedd remake the 'Candyman' song from old commercials. As an extension, we created an interactive AR experience. We figured what's a better way to get our product in our customer's hands than allowing them to remix the song with candy?"
                },
                {
                    "type": "p",
                    "content": "We started with a prototype. Utilizing Max/MSP, a webcam on a tripod, a ringlight, and Ableton, we created an experience that allowed users to mix different parts of the song by virtually dropping M&M's into the machine. Different colors were different instrumentations. The prototype was a hit, and the client bought in instantly. "
                },
                {
                    "type": "video",
                    "content": remix4
                },
                {
                    "type": "p",
                    "content": "For the build, we leveraged WebGL and JavaScript to create a web-based platform where users could see virtual M&M's appear through their device's camera and use them to mix different parts of the song. The result was an immersive, interactive experience that connected music, technology, and M&M's in a playful and memorable way."
                },
                {
                    "type": "youtube",
                    "content": "0kDCcvix1KA"
                }
            ]

        },


        {
            id: 13,
            title: 'Franks Red Hot Game',
            subtitle: 'Put that $#!% on everything',
            tags: ['Javascript', 'Web Design', 'Marketing', 'Dev', 'Gaming', 'pixijs'],
            image: [franks],
            content: [
                {
                    "type": "h1",
                    "content": "Frank's RedHot: The Game"
                },
                {
                    "type": "p",
                    "content": "I created a web game for Frank's RedHot using Pixi.js for use with a social campaign and banner ads. The game features simple yet addictive mechanics, offering users a playful and engaging experience that promotes the brand."
                },
                {
                    "type": "p",
                    "content": "Built for seamless performance, the game captures the bold spirit of Frank's RedHot, encouraging repeated playthroughs and providing a memorable, entertaining experience."
                },
                {
                    "type": "h3",
                    "content": "Use spacebar to play (or tap the screen)"
                },
                {
                    "type": "iframe",
                    "content": "/franks/index.html"
                }
            ]
        },

        {
            id: 15,
            title: 'Mountain Dew Kitty Wop',
            tags: ['Javascript', 'Web Design', 'Marketing', 'Dev', 'React'],
            image: [kittywop1],
            content: [
                {
                    "type": "h1",
                    "content": "Mountain Dew: Kitty Wop Web Experience"
                },
                {
                    "type": "p",
                    "content": "For this project, I had the exciting opportunity to design and develop the interactive web experience for Mountain Dew's Kitty Wop campaign. This experience was created to engage users with the viral dancing cat from the 'Freak Chain' commercial, extending the fun and energy of the ad into a fully interactive web experience."
                },
                {
                    "type": "p",
                    "content": "I built a custom platform that allowed users to create their own personalized dance sequences featuring the iconic dancing cat. Users could choose from a variety of dance moves and music tracks, making the experience fun and customizable. The goal was to amplify the viral nature of the campaign by allowing people to actively participate and engage with the content in a meaningful way."
                },
                {
                    "type": "p",
                    "content": "The web experience was designed to seamlessly tie into the larger marketing efforts of the campaign, reflecting Mountain Dew's energetic and playful brand. It encouraged social sharing, allowing users to post their unique dance creations on social media and further promote the campaign, extending its reach and engagement."
                },
                {
                    "type": "p",
                    "content": "By combining creativity with technical development, the Kitty Wop web experience became an engaging extension of Mountain Dew's brand, offering fans a fun, shareable experience that resonated with them well beyond the commercial itself."
                },
                {
                    "type": "video",
                    "content": wigglewop
                },
                {
                    "type": "grid",
                    "content": [
                        {
                            "type": "image",
                            "content": kittywop3
                        },
                        {
                            "type": "image",
                            "content": kittywop4
                        },
                        {
                            "type": "image",
                            "content": kittywop5
                        },
                        {
                            "type": "image",
                            "content": kittywop6
                        },
                        {
                            "type": "image",
                            "content": kittywop7
                        },
                        {
                            "type": "image",
                            "content": kittywop8
                        },
                        {
                            "type": "image",
                            "content": kittywop9
                        },
                        {
                            "type": "image",
                            "content": kittywop10
                        },
                        {
                            "type": "image",
                            "content": kittywop11
                        },
                        {
                            "type": "image",
                            "content": kittywop12
                        },
                        {
                            "type": "image",
                            "content": kittywop13
                        },
                        {
                            "type": "image",
                            "content": kittywop14
                        },
                        {
                            "type": "image",
                            "content": kittywop15
                        }
                    ]
                }
            ]
        },
        {
            id: 11,
            title: 'Bearbones',
            subtitle: 'Code generator and component library',
            tags: ['Javascript', 'Web Design', 'Marketing', 'Dev', 'React'],
            image: [test4, test3, test2, test1],
            content: [
                {
                    "type": "h1",
                    "content": "Bearbones: React Code Generator & Automator"
                },
                {
                    "type": "p",
                    "content": "I developed Bearbones, a React-based code generator and component library, to streamline the process of building websites with unique elements like 3D interactions, WebGL, and games. The goal was to efficiently create these sites while maintaining flexibility and consistency."
                },
                {
                    "type": "p",
                    "content": "Bearbones combines a generator system with a component library, allowing it to automatically generate project boilerplates based on predefined components. It parses data from spreadsheets provided by the new business team and converts that into clean, functional code for each new project."
                },
                {
                    "type": "p",
                    "content": "This system saved about 75% of development time on each project, allowing for faster delivery of high-quality web experiences. It was used across multiple projects and contributed to significant business wins for the agency."
                },
                {
                    "type": "h3",
                    "content": "A note: These sites have proprietary data, i will display them here once i've stripped their content"
                }
            ]

        },
        {
            id: 6,
            title: 'Taco Bell TikTok Musical',
            image: [tbtiktok1],
            tags: ['Social Media', 'Marketing', 'Creative', 'TikTok'],
            content: [
                {
                    "type": "h1",
                    "content": "Taco Bell - Mexican Pizza: The Musical"
                },
                {
                    "type": "p",
                    "content": "I worked as a creative on Taco Bell's Mexican Pizza: The Musical, a TikTok livestream event starring Dolly Parton and Doja Cat. The musical, celebrating the return of Taco Bell's Mexican Pizza, was a unique fusion of entertainment and marketing, combining original songs, choreography, and appearances by over 20 TikTok influencers."
                },
                {
                    "type": "p",
                    "content": "The campaign started with Doja Cat posting a variety of social content calling out Taco Bell for removing mexican pizza from their menu. After months of back and forth and a lot of engagement from the community, Doja got to tell her fans that she got mexican pizza back and it was all announced through a TikTok musical created by our team and a team of TikTok influencers. In addition to creative concepting, I oversaw the livestream production, ensuring a seamless broadcast experience for viewers. The event was streamed live on TikTok, attracting massive engagement and giving fans a fun, interactive way to celebrate the brand's iconic dish."
                },
                {
                    "type": "youtube",
                    "content": "5odcQsDqhOA"
                }
            ]
        }
    ]
}

export default homeContent