import React, { useEffect, useState } from 'react'

import homeContent from './Home-Content.jsx'
import Logo from '../../components/Logo/Logo.jsx'
import ProjectCardGrid from '../../components/ProjectCardGrid/ProjectCardGrid.jsx'
import ProjectCard from '../../components/ProjectCard/ProjectCard.jsx'
import PlaygroundButton from '../../components/PlaygroundButton/PlaygroundButton.jsx'
import Modal from '../../components/UI/Modal/Modal.jsx'

import useMousePosition from '../../hooks/useMousePosition'

const Home = () => {
  const [currentProjectSelection, setCurrentProjectSelection] = useState(null)
  const [modalContent, setModalContent] = useState({})
  const mousePosition = useMousePosition();


  useEffect(() => {
    if (currentProjectSelection !== null) {
      const selectedProject = homeContent.projects.find(project => project.id === currentProjectSelection)
      setModalContent(selectedProject.content)
    }
  }, [currentProjectSelection])

  const handleProjectSelection = (id) => {
    setCurrentProjectSelection(id)
  }

  const closeModal = () => {
    setCurrentProjectSelection(null)
  }

  return (
    <div className="home">
      <Logo />
      <ProjectCardGrid>
        {homeContent.projects.map((project, index) => (
          <ProjectCard key={index} project={project} clickHandler={handleProjectSelection} mousePosition={mousePosition} />
        ))}
      </ProjectCardGrid>
      {/* <PlaygroundButton /> */}
      <Modal show={currentProjectSelection !== null ? true : false} closeModal={closeModal} content={modalContent} />
    </div>
  )
}

export default Home