import React from 'react'

import playgroundButton from '../../assets/images/playground-button.png'

const PlaygroundButton = () => {
    return (
        <div className="playgroundButton">
            <img src={playgroundButton} alt="Playground Button" />
        </div>
    )
}

export default PlaygroundButton