import React, { useState, useEffect } from 'react'

import useWindowSize from '../../hooks/useWindowSize'



const ParallaxLayeredImage = ({ src, mousePosition }) => {
    const { width, height } = useWindowSize()
    return (
        <div className="parallaxLayeredImage">
            {Array.isArray(src) ? src.map((image, index) => {
                return <img key={index} src={image} alt="parallax-layered" style={{ zIndex: 1 + index, transform: `translate(${(mousePosition.x - (width / 2)) * 1 / (16 * ((src.length - index) + 1))}px, ${(mousePosition.y - (height / 2)) * 1 / (16 * ((src.length - index) + 1))}px) scale(${1 + (0.05 + (0.05 * (index + 1)))})` }} />
            }) : <img src={src} alt="parallax-layered" />}
        </div>
    )
}

export default ParallaxLayeredImage