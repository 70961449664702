import React from 'react';



const ProjectCardGrid = ({ children }) => {

    return (
        <section className="projectCardGrid">
            {children}
        </section>
    );
}

export default ProjectCardGrid;