import React from 'react'
import Navigation from '../components/Navigation/Navigation.jsx'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'


// ROUTE IMPORTS
import Home from '../pages/Home/Home.jsx'


const Routes = () => {

  const router = createBrowserRouter([
    {
      path: '/',
      Component: Navigation,
      children: [
        {
          path: ':path?',
          Component: Home,
          // loader: async () => {
          //   const cont = await getContent('Home')
          //   const content = fileParser(cont)
          //   return { content }
          // }
        },
        // NAV ROUTER
      ]
    },
    // OUT ROUTER
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default function App() {
  return (
    <Routes />
  )
}