import React, { useState, useEffect } from 'react';

const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const updateMousePosition = (ev) => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };

        window.addEventListener('mousemove', updateMousePosition);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []); // Empty array ensures the effect runs once when the component mounts

    return mousePosition;
};

export default useMousePosition;