import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowSize() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isMobile, setIsMobile] = useState(getWindowDimensions().width < 1440 ? true : false);
  const navigate = useNavigate();
  useEffect(() => {
    function handleResize() {
      var dim = getWindowDimensions();
      if (!isMobile && dim.width < 1440) {
        setIsMobile(true);
        // location.reload();
      }
      if (isMobile && dim.width >= 1440) {
        setIsMobile(false);
        // location.reload();
      }
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}