import { parse } from 'ipaddr.js';
import React from 'react';
import { Link } from 'react-router-dom';



export default function useContentParser(content) {
    var parsedContent = parseContent(content);
    return { parsedContent };
}

function parseContent(content) {
    if (!content || !content.length) return null;
    return content.map((block, index) => {
        switch (block.type) {
            case 'p':
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
            case 'li':
                const Tag = block.type;
                return <Tag key={index} className="contentBlockText">{block.content}</Tag>;
            case 'image':
                return <img key={index} src={block.content} alt={block.alt} className="contentBlockImage" />;
            case 'video':
                return <video key={index} src={block.content} controls className="contentBlockVideo" />;
            case 'youtube':
                return (
                    <iframe
                        key={index}
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${block.content}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="contentBlockYoutube"
                    ></iframe>
                );
            case 'ul':
                return (
                    <ul key={index} className="contentBlockList">
                        {parseContent(block.content)}
                    </ul>
                );
            case 'button':
                return (
                    <button
                        key={index}
                        className="contentBlockButton"
                        onClick={() => window.open(block.link, '_blank')}
                    >
                        {block.content}
                    </button>
                );
            case 'grid':
                return (
                    <div key={index} className="contentBlockGrid">
                        {block.content.map((item, i) => (
                            <div key={i} className="gridItem">
                                {parseContent([item])}
                            </div>
                        ))}
                    </div>
                );
            case 'iframe':
                return (
                    <iframe
                        key={index}
                        src={block.content}
                        title="Iframe content"
                        frameBorder="0"
                        allowFullScreen
                        className="contentBlockIframe"
                    ></iframe>
                );
            default:
                return null;
        }
    });
}
