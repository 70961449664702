import React from 'react'

import ParallaxLayeredImage from '../ParallaxLayeredImage/ParallaxLayeredImage'
import ProjectCardTitlePanel from '../ProjectCardTitlePanel/ProjectCardTitlePanel'

const ProjectCard = ({ project, clickHandler, mousePosition }) => {
    return (
        <div className="projectCard" onClick={() => clickHandler(project.id)}>
            <ParallaxLayeredImage src={project.image} mousePosition={mousePosition} />
            <ProjectCardTitlePanel title={project.title} subtitle={project.subtitle} />
        </div>
    )
}

export default ProjectCard